<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="1000px"
    origin="left top"
    scrollable
  >
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text class="mb-6">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row v-if="!isInsert">
            <v-col class="d-flex justify-center">
              <AvatarForm
                :uploadUrl="uploadUrl"
                :associate="form"
                :media="form.media[0]"
              />
            </v-col>
          </v-row>
          <h3 class="mt-4 mb-2">Informações</h3>
          <v-row>
            <v-col cols="6">
              <v-radio-group
                class="mt-0"
                hide-details="auto"
                v-model="form.person_type"
                row
              >
                <v-radio label="Pessoa Física" value="fisica"> </v-radio>
                <v-radio label="Pessoa Jurídica" value="juridica"> </v-radio>
              </v-radio-group>
            </v-col>
            <v-col offset="4" cols="2">
              <app-code-field
                label="Código"
                v-model="form.code"
                :clear-on-auto-code="isInsert"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col v-if="IsJuridic" md="6">
              <app-cnpj-field v-model="form.cnpj" label="CNPJ" />
            </v-col>
            <v-col v-else md="6">
              <app-cpf-field v-model="form.cpf" label="CPF" />
            </v-col>
            <v-col md="6">
              <app-text-field
                v-model="form.whatsapp_contact"
                label="WhatsApp"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col md="6">
              <app-text-field v-model="form.name" :label="name_label" />
            </v-col>
            <v-col md="6">
              <app-text-field v-model="form.alias" :label="alias_label" />
            </v-col>
          </v-row>
          <v-row>
            <template v-if="IsJuridic">
              <v-col md="4">
                <app-text-field
                  :disabled="form.inscricao_estadual_isento == 1"
                  v-model="form.inscricao_estadual"
                  label="Inscrição Estadual"
                />
              </v-col>
              <v-col md="2">
                <v-checkbox
                  class="mt-0"
                  v-model="form.inscricao_estadual_isento"
                  label="Isento"
                  hide-details="auto"
                  :true-value="1"
                  :false-value="0"
                />
              </v-col>
            </template>
            <template v-else>
              <v-col md="6">
                <app-text-field v-model="form.rg" label="RG" />
              </v-col>
            </template>
            <v-col>
              <app-select
                :items="occupation"
                label="Profissão"
                v-model="form.occupation"
              />
            </v-col>
          </v-row>
          <h3 class="mt-10 mb-6">Endereços</h3>
          <address-form v-model="form.main_address" />

          <template v-for="(address, index) in form.additional_address">
            <div v-if="address.status == 1" :key="index">
              <address-form
                :value="address"
                class="my-10"
                ref="AddressForm"
              ></address-form>
            </div>
          </template>

          <v-btn
            text
            class="mt-4 text-capitalize"
            color="secondary"
            @click="addRow()"
            ><v-icon>mdi-plus</v-icon> Adicionar
          </v-btn>

          <h3 class="mt-10 mb-4">Telefones</h3>
          <v-row>
            <v-col>
              <phone-contacts-form v-model="form.phones" />
            </v-col>
          </v-row>
          <h3 class="mt-10 mb-4">Emails e sites</h3>
          <v-row>
            <v-col>
              <email-contacts-form v-model="form.emails" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <site-contacts-form v-model="form.sites" />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex">
              <v-switch
                class="mr-10"
                v-model="form.app_status"
                :true-value="1"
                :false-value="0"
                :label="appStatusText[form.app_status]"
              />
              <app-status-switch v-model="form.status" />
            </v-col>
          </v-row>
        </v-form>
        <!-- <v-row class="mt-5">
          <v-col>
            <v-card color="backgroud" elevation="0">
              <app-activities v-if="!isInsert" :activities="form.activities" />
            </v-card>
          </v-col>
        </v-row> -->
      </v-card-text>

      <v-card-actions
        class="justify-end py-4"
        style="background-color: #f5f5f5"
      >
        <v-btn
          @click="dialog = false"
          text
          class="text-capitalize"
          color="secondary"
          >Cancelar</v-btn
        >
        <v-btn @click="handleSave()" class="text-capitalize" color="primary"
          >Salvar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import cep from "cep-promise";
import PhoneContactsForm from "@/components/app/forms/PhoneContactsForm.vue";
import EmailContactsForm from "@/components/app/forms/EmailContactsForm.vue";
import SiteContactsForm from "@/components/app/forms/SiteContactsForm.vue";
import AddressForm from "../../app/forms/AddressForm.vue";
import AvatarForm from "@/components/app/forms/AvatarForm";

export default {
  components: {
    PhoneContactsForm,
    EmailContactsForm,
    SiteContactsForm,
    AddressForm,
    AvatarForm,
  },
  data() {
    return {
      uploadUrl: "professional/avatar",
      appStatusText: {
        1: "Visivel no aplicativo",
        0: "Oculto no aplicativo",
      },
      dialog: false,
      statusText: {
        1: "Este cadastro está ativo",
        0: "Este cadastro está inativo",
      },
      rules: {
        required: (value) => !!value || "Required.",
      },

      occupation: [
        { text: "Eletricista", value: "Eletricista" },
        { text: "Gesseiro", value: "Gesseiro" },
        { text: "Pintor", value: "Pintor" },
        { text: "Pedreiro", value: "Pedreiro" },
        { text: "Bombeiro Hidráulico", value: "Bombeiro Hidráulico" },
        // {
        //   text: "Servente ou Ajudante de Obra",
        //   value: "Servente ou Ajudante de Obra",
        // },
        // { text: "Arquiteto e urbanista", value: "Arquiteto e urbanista" },
        // { text: "Engenheiro Civil", value: "Engenheiro Civil" },
        // { text: "Mestre de Obras", value: "Mestre de Obras" },
        // { text: "Apontador de Obras", value: "Apontador de Obras" },
        // {
        //   text: "Técnico em Construção Civil",
        //   value: "Técnico em Construção Civil",
        // },
        // { text: "Armador", value: "Armador" },
        // { text: "Carpinteiro", value: "Carpinteiro" },
      ],

      title: "Cadastrar Profissional",

      valid: false,
      form: {},
      baseForm: {
        code: null,
        person_type: "fisica",
        name: null,
        alias: null,
        cpf: null,
        cnpj: null,
        rg: null,
        status: 1,
        app_status: 0,
        inscricao_estadual: null,
        inscricao_estadual_isento: null,
        main_address: {
          address: null,
          number: null,
          complement: null,
          district: null,
          postal_code: null,
          city_district: null,
          city: null,
          state: null,
          country: null,
          type: "mainAddress",
        },
        phones: [],
        emails: [],
        sites: [],
        additional_address: [
          {
            address: null,
            addressable_id: null,
            addressable_type: null,
            city: null,
            city_code: null,
            complement: null,
            country: null,
            country_code: null,
            created_at: null,
            district: null,
            id: null,
            number: null,
            postal_code: null,
            state: null,
            state_code: null,
            status: null,
            type: null,
          },
        ],
      },
    };
  },
  computed: {
    IsJuridic() {
      return this.form.person_type == "juridica";
    },
    alias_label() {
      if (this.IsJuridic) {
        return "Nome Fantasia";
      } else {
        return "Apelido";
      }
    },

    name_label() {
      if (this.IsJuridic) {
        return "Razão Social";
      } else {
        return "Nome";
      }
    },
    isInsert() {
      return !this.form.id;
    },
  },
  created() {
    this.reset();
  },

  methods: {
    async open(id) {
      this.reset();
      if (id) {
        await this.showProfessional(id);
      }
      this.setDefaults();
      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
      this.title = "Cadastrar Profissional";
    },

    setDefaults() {
      this.form.phones.push({
        contact: null,
      });

      this.form.emails.push({
        contact: null,
      });

      if (this.form.sites.length == 0) {
        this.form.sites.push({
          contact: null,
        });
      }
    },

    async showProfessional(id) {
      this.title = "Atualizar Profissional";
      this.$store.commit("app/showLoading");
      await this.$http
        .$get("professional/professional/" + id)
        .then((response) => {
          this.form = response.professional;
          this.$store.commit("app/hideLoading");
        });
    },

    handleSave() {
      this.$refs.form.validate();
      if (this.valid == true) {
        if (this.isInsert) {
          this.store();
        } else {
          this.update();
        }
      }
    },

    store() {
      this.$http
        .store("professional/professional", this.form)
        .then((response) => {
          this.processSaved();
        });
    },

    update() {
      this.$http
        .update("professional/professional", this.form.id, this.form)
        .then((response) => {
          this.processSaved();
        });
    },

    processSaved() {
      this.$emit("store");
      this.dialog = false;
    },

    getZipCode() {
      cep(this.form.main_address.postal_code)
        .then((response) => {
          this.form.main_address.city = response.city;
          this.form.main_address.address = response.street;
          this.form.main_address.district = response.neighborhood;
          this.form.main_address.state = response.state;
        })
        .catch((error) => {
          alert(error);
        });
    },
    removeRow(index) {
      this.form.additional_address[index].status = 0;
    },
    addRow() {
      this.form.additional_address.push({
        address: null,
        addressable_id: null,
        addressable_type: null,
        city: null,
        city_code: null,
        complement: null,
        country: null,
        country_code: null,
        created_at: null,
        district: null,
        id: null,
        number: null,
        postal_code: null,
        state: null,
        state_code: null,
        status: 1,
        type: null,
      });
    },
  },
};
</script>

<style scoped></style>
